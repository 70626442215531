<template>
    <div class="flex flex-col lg:flex-row mx-6 my-3">
        <div class="w-24 md:w-28 lg:w-32 xl:w-28 py-3 h-fit my-auto mx-auto xl:mx-0">
            <!-- <img class="my-auto" :src="$props.icon" :alt="iconAlt"> -->
            <IKImage
                    class="my-auto"
                    :path="$props.icon"
                    :alt="iconAlt"
                    :lqip="{active:true}"
                    width="120"
                    height="120"
            />
        </div>
        <div class="w-11/12 md:w-58 lg:w-60 xl:w-80 px-4 py-3">
            <h3 class="text-left text-gpl-green">{{ $props.title }}</h3>
            <p class="text-left text-gpl-blue">
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MissionCard',
    props: {
        title: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        iconAlt: {
            type: String,
            default: '',
        },
    },
};
</script>
