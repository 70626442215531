<template>
    <div class="w-11/12 2xl:w-2/6 my-4 mx-auto md:mx-3 hover:scale-105 md:hover:scale-110 xl:hover:scale-125 hover:cursor-pointer transition-all ease-in" @click="openCompany($props.companyLink)">
        <div class="grid grid-cols-12 h-full bg-gpl-green rounded-xl xl:rounded-full hovver:shadow-xl hover:shadow-white/10">
            <div class="col-span-full md:col-span-full xl:col-span-5 bg-white 
                rounded-tl-xl rounded-tr-xl xl:rounded-l-full xl:rounded-r-full 
                w-full h-full text-center 
                xl:text-right py-4 flex justify-center items-center
            ">
                <!-- LOGO -->
                <!-- <img :src="$props.companyLogo" 
                    class="object-contain mx-auto w-3/5 h-24 lg:h-18 2xl:h-18 object-center xl:object-right align-middle rounded-xl " 
                    :alt="$props.companyName"
                /> -->
                <IKImage
                    class="object-contain mx-auto w-3/5 h-18 lg:h-18 2xl:h-18 object-center xl:object-right align-middle rounded-xl"
                    :path="$props.companyLogo"
                    :lqip="{active:true}"
                    width="150"
                    height="100"
                    quality="0.8"
                />
            </div>
            <div class="col-span-full md:col-span-full xl:col-span-7 h-full 
                rounded-bl-xl rounded-br-xl xl:rounded-r-full">
                <!-- COMPANY INFO -->
                <div class="my-auto align-middle py-4 grid grid-cols-1 content-center h-full">
                    <div class="col-span-full">
                        <p class="uppercase text-xl md:text-xl xl:text-lg 2xl:text-xl text-white font-bold text-left px-10">
                            <font-awesome-icon icon="fa-solid fa-location-dot" /> &nbsp; {{ $props.companyPlace }}
                        </p>
                        <p class="text-xl md:text-xl 2xl:text-xl xl:text-lg text-white font-bold text-left px-10">
                            {{ $props.companyName }}
                        </p>
                    </div>
                    <!-- <div class="row-span-1 py-2 text-left">
                        <router-link :to="$props.companyLink" class="text-lg md:text-xl 2xl:text-3xl text-white font-bold text-left px-10">
                            Scopri di più &nbsp;
                            <font-awesome-icon icon="fa-solid fa-arrow-right"></font-awesome-icon>
                        </router-link>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CompanyRoundedButton',
    components: {

    },
    props: {
        companyName: {
            type: String,
            default: '',
        },
        companyPlace: {
            type: String,
            default: '',
        },
        companyLogo: {
            type: String,
            default: '',
        },
        companyLink: {
            type: String,
            default: '/',
        },
    },
    methods: {
        openCompany(link) {
            if (link === null || link === '/') {
                return;
            }
            this.$router.push(link);
        },
    },
};
</script>
