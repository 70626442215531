import { createApp } from 'vue';
import { createImageKitVue } from "imagekit-vue3";
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import VueSmoothScroll from 'vue3-smooth-scroll';
/* import specific icons */
import { 
    faArrowRight,
    faPaperPlane,
    faLeaf,
    faLightbulb,
    faHandsPraying,
    faLink,
    faLocationDot,
    faPhone,
    faEnvelope,
    faGlobe,
    faAt,
    faBuilding,
    faArrowLeft,
    faCalendar,
 } from '@fortawesome/free-solid-svg-icons';
 import { 
    faFacebook, 
    faInstagram, 
    faLinkedin, 
    faLinkedinIn,
    faTwitter,
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import VueSocialSharing from 'vue-social-sharing';
 import App from './App.vue';
 import router from './router';
 import store from './store';
import '@/assets/css/main.css';

/* add icons to the library */
library.add(faPaperPlane);
library.add(faArrowRight);
library.add(faArrowLeft);
library.add(faLeaf);
library.add(faLightbulb);
library.add(faHandsPraying);
library.add(faLinkedinIn);
library.add(faFacebook);
library.add(faInstagram);
library.add(faLinkedin);
library.add(faFacebook);
library.add(faLink);
library.add(faLocationDot);
library.add(faPhone);
library.add(faEnvelope);
library.add(faGlobe);
library.add(faAt);
library.add(faBuilding);
library.add(faTwitter);
library.add(faCalendar);
library.add(faWhatsapp);

const app = createApp(App);
// app.use(VueSmoothScroll, {
//     duration: 400,
//     updateHistory: false,
// });
app.use(store);
app.use(router);
app.use(VueSocialSharing);
app.use(createImageKitVue({
    publicKey: 'public_2vLV9Bi1mycAZ3XWiwQLIxjGhsU=',
    urlEndpoint: "https://ik.imagekit.io/z0nni0jpl/gpl/", // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
    registerGlobalComponents: true, // optional. Default is false, this will register all ImageKitVue components globally
}));
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
