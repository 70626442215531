<template>
    <div class="w-full bg-gpl-blue bg-fixed bg-right bg-cover contacts-bg">
        <div class="w-full bg-gpl-blue/90 py-8">
            <h1 class="font-semibold text-gpl-white text-4xl lg:text-5xl xl:text-5xl">
                Contattaci
            </h1>
        </div>
        <div class="
        w-full xl:w-5/6 2xl:w-4/5
        xl:mx-auto
        grid lg:grid-cols-6 xl:grid-cols-6 py-12">
            <div class="col-span-full flex flex-row">
                <div class="w-4/5 mx-auto py-5">
                    <form class="flex flex-col xl:w-5/6 2xl:w-4/6 xl:mx-auto bg-gpl-green rounded-xl px-8 py-8">
                        <!-- <h3 class="text-xl text-white text-left text-3xl mb-3">Scrivi un messaggio</h3> -->
                        <div class="flex flex-col justify-start">
                            <label for="name" 
                                class="text-white font-semibold text-left ml-4"
                            >Nome:</label>
                            <input type="text" class="
                                border-2 border-white focus:border-gpl-blue rounded-full 
                                text-lg
                                px-4 my-2 py-1"/>
                        </div>
                        <div class="flex flex-col justify-start">
                            <label for="name" 
                                class="text-white font-semibold text-left ml-4"
                            >Cognome:</label>
                            <input type="text" class="
                                border-2 border-white focus:border-gpl-blue rounded-full 
                                text-lg
                                px-4 my-2 py-1"/>
                        </div>
                        <div class="flex flex-col justify-start">
                            <label for="name" 
                                class="text-white font-semibold text-left ml-4"
                            >Azienda:</label>
                            <input type="text" class="
                                border-2 border-white focus:border-gpl-blue rounded-full 
                                text-lg
                                px-4 my-2 py-1"/>
                        </div>
                        <div class="flex flex-col justify-start">
                            <label for="name" 
                                class="text-white font-semibold text-left ml-4"
                            >Messaggio:</label>
                            <textarea rows="6" class="
                                w-full
                                border-2 border-white focus:border-gpl-blue rounded-xl 
                                text-lg text-left
                                my-2 py-1" style="align-content:start">
                            </textarea>
                        </div>
                        <div class="flex flex-row justify-start">
                            <input type="checkbox" class="
                                border-2 border-white focus:border-gpl-blue rounded-full 
                                text-lg
                                px-4 my-2 py-1"/>
                            <label for="name" 
                                class="text-white font-semibold text-left ml-4 text-sm align-middle py-1"
                            >Accetto i termini e le condizioni dell'informativa </label>
                        </div>
                        <div class="flex flex-row justify-center my-3 w-full 2xl:w-2/5 mx-auto">
                            <button class="w-full bg-white hover:bg-gpl-blue text-gpl-green hover:text-white p-4 border-none font-black rounded-full">
                                Invia la richiesta
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactsSection',

};
</script>

<style>
.contacts-bg {
    box-shadow: inset 0 0 0 1000px rgba(28,42,63,.4);
    background-image: url('https://ik.imagekit.io/z0nni0jpl/gpl/gpl/contacts-bg.jpg?tr=w-2048,q-20');
}
</style>
