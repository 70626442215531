<template>
    <div>
        <Navbar></Navbar>
        <div class="min-h-screen">
            <div class="block absolute top-0 left-0 z-[-1]">
                <div class="w-screen h-screen" style="background-image: url('https://ik.imagekit.io/z0nni0jpl/gpl/gpl/truck-bg-min.jpg?updatedAt=1683205494147');">

                </div>
            </div>
            <div class="hidden xl:block absolute
                xl:left-[68%] xl:top-[-2%] 
                2xl:left-[64%] 2xl:top-[-5%] 
                z-[100] h-[150%]">
                <svg class="w-[220%] float-right h-full hero-animate opacity-95" viewBox="0 0 861 1277" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="664.189" cy="609.199" rx="772.227" ry="537.633" transform="rotate(-45.3471 664.189 609.199)" fill="#61B985" />
                </svg>
            </div>
            
            <slot></slot>
            
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
import Navbar from "@/components/common/Navbar.vue";
// import Footer from "@/components/common/Footer.vue";

export default {
    name: '',
    components: {
        Navbar,
        // Footer,
    },
    data() {
        return {

        };
    },

};
</script>

<style>
.hero-animate {
    animation: hero;
    animation-duration: 0.8s;

}
@keyframes hero {
    0% {
        transform: scale(500%);
        opacity: 1;
        /* z-index: 200; */
    }
    50% {
        /* transform: rotate(180deg); */
    }
    99% {
        /* z-index: 200; */
    }
    100% {
        /* width: 220%; */
        transform: scale(100%);
        z-index: 100;
        opacity: 0.95;
    }
}
.hero {

}
</style>
