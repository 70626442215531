import { createRouter, createWebHistory } from 'vue-router';
import Homepage from '@/views/Homepage/Homepage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Homepage,
  },
  {
    path: '/news/:newsId',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '@/views/News/News.vue'),
  },
  {
    path: '/network/:companyId',
    name: 'company',
    component: () => import(/* webpackChunkName: "company" */ '@/views/CompanyPage/CompanyPage.vue'),
  },
  // {
  //   path: '/contatti',
  //   name: 'contacts',
  //   component: () => import(/* webpackChunkName: "contacts" */ '@/views/ContactPage/ContactPage.vue'),
  // },
  {
    path: '/downloads',
    name: 'downloads',
    component: () => import(/* webpackChunkName: "downloads" */ '@/views/DownloadsPage/DownloadsPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' });
    }
    return window.scrollTo(0, 0);
  },
});

export default router;
