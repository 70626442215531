<template>
    <div class="w-full mx-auto xl:mx-4 my-8 md:w-[24rem] md:my-3 xl:my-0 hover:scale-105 transition-all ease-in group">
        <div class="w-full">
            <!-- Cover-->
            <!-- <img 
                :src="$props.mainImg" 
                class="object-cover  
                        min-h-[18rem] xl:h-[10rem] 
                        rounded-tl-[5rem] rounded-tr-[5rem] 
                " alt="A title"/> -->
                <IKImage
                    class="object-cover  
                            min-h-[18rem] xl:h-[10rem] 
                            rounded-tl-[5rem] rounded-tr-[5rem]"
                    :path="$props.mainImg"
                    :lqip="{active:true}"
                    width="400"
                    height="300"

                />
        </div>
        <div class="relative 
            w-full 
            min-h-[16rem] xl:min-h-[16rem] 
            bg-gpl-blue 
            rounded-bl-[5rem] rounded-br-[2rem] xl:rounded-br-[2rem] 
            pt-6 pb-0 hover:shadow-lg ">
            <div class="">
                <h3 class="px-5 text-white text-xl xl:text-lg font-bold text-left text-break">
                    <slot name="title"></slot>
                </h3>
            </div>
            <!-- <div class="">
                <p class="
                    px-5 text-neutral-200 
                    text-xs xl:text-sm 
                    font-regular py-4 text-left trunc-me 
                    hidden md:block
                    ">
                    <slot name="brief"></slot>
                </p>
            </div> -->
            <div class=" mt-4 mb-5 xl:mb-10">
                <p class="px-5 text-neutral-400 text-xs text-left">
                    <slot name="date"></slot>
                </p>
            </div>
                <a
                    :href="$props.routeLink"
                    class="absolute bottom-[-1px] right-0 bg-gpl-green/80 
                        px-[1rem] py-[1rem] xl:px-[1.2rem] xl:py-[1.2rem]  
                        rounded-tl-full rounded-bl-full rounded-br-full 
                        text-lg xl:text-xl font-black border-none uppercase
                        text-white 
                        group-hover:bg-gpl-green group-hover:text-white group-hover:border-3 group-hover:border-gpl-green">
                    Leggi &nbsp;
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NewsCard',
        props: {
            routeLink: {
                type: String,
                default: '',
            },
            mainImg: {
                type: String,
                default: '',
            },
        },
    };
</script>
