<template>
    <div class="w-full grid grid-cols-7 bg-white py-12">
        <div class="col-span-full mb-10">
            <h1 class="font-black text-gpl-blue text-4xl lg:text-5xl">News</h1>
        </div>
        <div class="col-span-full flex flex-col xl:flex-row content-center px-8 md:px-0 xl:justify-center">
            <NewsCard 
                v-for="n,i in newsData.slice(startAt,endAt)" :key="i" 
                :mainImg="n.mainImg" 
                :routeLink="`/news/${n.slug}`">
                <template v-slot:title>
                    {{ n.title}}
                </template>
                <template v-slot:brief>
                    {{ ellipseText(n.brief, 40) }}
                </template>
                <template v-slot:date>
                    {{ n.date}}
                </template>
            </NewsCard>
            <NewsCard
                v-if="(endAt + 1) < newsData.length"
                :mainImg="newsData[endAt+1].mainImg"
                :routeLink="`/news/${newsData[endAt+1].slug}`"
                class="hidden">
                <template v-slot:title>
                    {{ newsData[endAt+1].title}}
                </template>
                <template v-slot:brief>
                    {{ ellipseText(newsData[endAt+1].brief, 40) }}
                </template>
                <template v-slot:date>
                    {{ newsData[endAt+1].date}}
                </template>
            </NewsCard>
        </div>
        <div class="col-span-full flex flex-row justify-center mt-5">
            <button 
                class="mx-3 p-3 px-5 bg-gpl-green rounded-full text-lg text-white disabled:opacity-70 transition-all ease-in"
                @click="slidePrev"
                :disabled="startAt - 1 < 0"
            >
                <font-awesome-icon icon="fa-solid fa-arrow-left"></font-awesome-icon>
            </button>
            <button
                class="mx-3 p-3 px-5 bg-gpl-green rounded-full text-lg text-white disabled:opacity-70 transition-all ease-in" 
                @click="slideNext"
                :disabled="endAt + 1 > newsData.length"
            >
                <font-awesome-icon icon="fa-solid fa-arrow-right"></font-awesome-icon>
            </button>
        </div>
    </div>
</template>

<script>
import NEWS from '@/store/news';
import NewsCard from './NewsCard.vue';

export default {
    name: 'NewsSection',
    components: {
        NewsCard,
    },
    data() {
        return {
            newsData: NEWS,
            startAt: 0,
            endAt: 3,

        };
    },
    mounted() {
        if (window.innerWidth < 768) {
            // sm
            this.endAt = 1;
        } else if (this.innerWidth < 1024) {
            this.endAt = 2;
        } else if (this.innerWidth < 1563) {
            this.endAt = 3;
        } else {
            this.endAt = 4;
        }
    },
    methods: {
        ellipseText(text, words) {
            const slices = text.split(" ").slice(0, words);
            if (slices.length > words) {
                return text;
            }
            return `${slices.join(" ")}...`;
        },
        slideNext() {
            if (this.endAt + 1 <= NEWS.length) {
                this.endAt += 1;
                this.startAt += 1;
            }
        },
        slidePrev() {
            if (this.startAt - 1 >= 0) {
                this.endAt -= 1;
                this.startAt -= 1;
            }
        },
    },
};
</script>
