<template>
    <MainLayout>
        <HeroMain></HeroMain>
        <div class="absolute right-0 z-[500] p-0 m-0">
            <StatsSection></StatsSection>
            <MissionSection id="obiettivi"></MissionSection>
            <NetworkSection id="la-nostra-rete"></NetworkSection>
            <NewsSection id="news"></NewsSection>
            <CoontactsSection id="contatti"></CoontactsSection>   
            <Footer></Footer>
        </div>
    </MainLayout>
</template>

<script>
import HeroMain from '@/components/Homepage/HeroMain.vue';
import MissionSection from '@/components/Homepage/MissionSection.vue';
import NetworkSection from '@/components/Homepage/NetworkSection.vue';
import NewsSection from '@/components/Homepage/NewsSection.vue';
import Footer from '@/components/common/Footer.vue';
import StatsSection from '@/components/Homepage/StatsSection.vue';
import CoontactsSection from '@/components/Homepage/CoontactsSection.vue';
import MainLayout from '../layouts/MainLayout.vue';

export default {
    name: 'Homepage',
    components: {
    MainLayout,
    HeroMain,
    MissionSection,
    NetworkSection,
    NewsSection,
    Footer,
    StatsSection,
    CoontactsSection,
},
    // mounted() {
    //     if (this.$route.hash) {
    //         console.log("Hash", this.$route.hash, document.getElementById(this.$route.hash));
    //         document.getElementById(this.$route.hash).scrollIntoView({ behavior: 'smooth' });
    //     }
    // },
};
</script>
