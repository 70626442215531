<template>
    <div class="w-full bg-gpl-blue bg-fixed bg-right bg-cover earth-bg">
        <div class="w-full bg-gpl-green/90 py-8">
            <h1 class="font-semibold text-gpl-white text-4xl lg:text-5xl xl:text-5xl">La Nostra Rete</h1>
        </div>
        <div class="w-full xl:w-5/6 2xl:w-4/5
        xl:mx-auto flex flex-col xl:flex-row flex-wrap py-8 justify-center">
        <CompanyRoundedButton
            v-for="c,i in companies" :key="i"
            :company-logo="c.logo"
            :company-name="c.name"
            :company-place="c.place"
            :company-link="c.content === null ? null : `/network/${c.slug}`"
        ></CompanyRoundedButton>
        </div>
        <!-- <div class="
        w-full xl:w-5/6 2xl:w-4/5
        xl:mx-auto
        grid lg:grid-cols-6 xl:grid-cols-6 py-12">
            <div class="col-span-full lg:col-span-4 lg:col-start-2 xl:col-span-3 2xl:col-span-3 mt-16 px-12">
                <CompanyRoundedButton 
                    :company-logo="companies[0].companyLogo"
                    :company-name="companies[0].companyName"
                    :company-place="companies[0].companyPlace"
                    :company-link="`/network/${companies[0].slug}`"
                ></CompanyRoundedButton>
            </div>
            <div class="col-span-full lg:col-span-4 lg:col-start-2 xl:col-span-3 2xl:col-span-3 mt-16 px-12">
                <CompanyRoundedButton
                    :company-logo="companies[1].companyLogo"
                    :company-name="companies[1].companyName"
                    :company-place="companies[1].companyPlace"
                    :company-link="`/network/${companies[1].slug}`"
                ></CompanyRoundedButton>
            </div>
            <div class="col-span-full lg:col-span-4 lg:col-start-2 xl:col-span-3 2xl:col-span-3 mt-16 px-12">
                <CompanyRoundedButton
                    :company-logo="companies[2].companyLogo"
                    :company-name="companies[2].companyName"
                    :company-place="companies[2].companyPlace"
                    :company-link="`/network/${companies[2].slug}`"
                ></CompanyRoundedButton>
            </div>
            <div class="col-span-full lg:col-span-4 lg:col-start-2 xl:col-span-3 2xl:col-span-3 mt-16 px-12">
                <CompanyRoundedButton
                    :company-logo="companies[3].companyLogo"
                    :company-name="companies[3].companyName"
                    :company-place="companies[3].companyPlace"
                    :company-link="`/network/${companies[3].slug}`"            
                ></CompanyRoundedButton>
            </div>
            <div class="col-span-full lg:col-span-4 lg:col-start-2 xl:col-span-3 2xl:col-span-3  mt-16 px-12">
                <CompanyRoundedButton
                    :company-logo="companies[4].companyLogo"
                    :company-name="companies[4].companyName"
                    :company-place="companies[4].companyPlace"
                    :company-link="`/network/${companies[4].slug}`"  
                ></CompanyRoundedButton>
            </div>
            <div class="col-span-full lg:col-span-4 lg:col-start-2 xl:col-span-3 2xl:col-span-3 mt-16 px-12">
                <CompanyRoundedButton
                    :company-logo="companies[5].companyLogo"
                    :company-name="companies[5].companyName"
                    :company-place="companies[5].companyPlace"
                    :company-link="`/network/${companies[5].slug}`"  
                ></CompanyRoundedButton>
            </div>
            <div class="col-span-full lg:col-span-4 lg:col-start-2 xl:col-span-3 2xl:col-span-3 mt-16 px-12">
                <CompanyRoundedButton
                    :company-logo="companies[6].companyLogo"
                    :company-name="companies[6].companyName"
                    :company-place="companies[6].companyPlace"
                    :company-link="`/network/${companies[6].slug}`"  
                ></CompanyRoundedButton>
            </div>
        </div> -->
    </div>
</template>

<script>
import COMPANIES from '@/store/companies';
import CompanyRoundedButton from './CompanyRoundedButton.vue';

export default {
    name: 'NetworkSection',
    components: {
        CompanyRoundedButton,
    },
    data() {
        return {
            companies: COMPANIES,
            // companies: [
            //     {
            //         companyName: 'Trasporti F.lli Primiceri',
            //         companyLogo: 'gpl/companies/primiceri.png',
            //         companyPlace: 'Lecce',
            //         slug: 'trasporti-flli-primiceri',
            //     },
            //     {
            //         companyName: 'Palladino Trasporti',
            //         companyLogo: 'gpl/companies/palladino.jpeg',
            //         companyPlace: 'Avellino',
            //         slug: 'palladino-logistics-srl',
            //     },
            //     {
            //         companyName: 'SO.LOG.',
            //         companyLogo: 'gpl/companies/solog.jpg',
            //         companyPlace: 'Sondrio',
            //         slug: 'so-log-srl',
            //     },
            //     {
            //         companyName: 'BS Group',
            //         companyLogo: 'gpl/companies/bs-group.jpg',
            //         companyPlace: 'Teramo',
            //         slug: 'bs-group-srl',
            //     },
            //     {
            //         companyName: 'Trial s.r.l.',
            //         companyLogo: 'gpl/companies/trial.webp',
            //         companyPlace: 'Milano',
            //         slug: 'trial-srl',
            //     },
            //     {
            //         companyName: 'Iorio Trasporti e Logistica.',
            //         companyLogo: 'gpl/companies/iorio.png',
            //         companyPlace: 'Napoli',
            //         slug: 'iorio-trasporti-e-logistica-spa',
            //     },
            //     {
            //         companyName: 'Cianciosi s.r.l.',
            //         companyLogo: 'gpl/companies/cianciosi.png',
            //         companyPlace: 'Chieti',
            //         slug: 'cianciosi-trasporti-e-servizi-srl',
            //     },
            // ],
        };
    },
};
</script>

<style>
.earth-bg {
    box-shadow: inset 0 0 0 1000px rgba(28,42,63,.4);
    background-image: url('https://ik.imagekit.io/z0nni0jpl/gpl/gpl/puzzle.jpg?tr=w-2048,q-40');
}
</style>
