<template>
    <div class="">
        <div class="w-1/3">
            <!-- ICON -->
            <img  class="h-4/5 lg:h-full px-5 xl:px-0 float-left" :src="$props.icon" :alt="$props.iconAlt" />
        </div>
        <div class="w-2/3">
            <!-- Text-->
            <p class="text-5xl lg:text-6xl 2xl:text-6xl text-white font-black uppercase text-left">{{ counterText }} <slot name="um"></slot></p>
            <p class="text-2xl lg:text-3xl 2xl:text-3xl text-white font-black uppercase text-left">{{ $props.brief }}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'StatButton',
    props: {
        icon: {
            type: String,
        },
        iconAlt: {
            type: String,
            default: '',
        },
        quantity: {
            type: String,
            default: '0+',
        },
        brief: {
            type: String,
            default: '',
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            counterText: '',
            obs: undefined,
            triggered: false,
        };
    },
    methods: {
        async count() {
            if (this.triggered) {
                return;
            }
            this.counterText = '0';
            const target = Number.parseInt(this.$props.quantity.slice(0, -1), 10);
            // const lastCh = this.$props.quantity[this.$props.quantity.length - 1];
            const interval = 1000 / (target / this.$props.step);
            for (let i = 0; i <= target; i += this.$props.step) {
                this.counterText = `${i}`;
                await new Promise((r) => setTimeout(r, interval));
            }
            
            // this.triggered = true;
        },
    },
    mounted() {
        // this.count();
        this.obs = new IntersectionObserver(this.count, {
            threshold: 0.5,
        });
        this.obs.observe(this.$el);
        // this.triggered = false;
    },
};
</script>
