<template>
    <nav class="
          p-0 px-0 
          flex flex-col xl:flex-row 
          py-2 xl:py-2 2xl:py-4 
          content-center
          h-[3.3rem] xl:h-[7vh] 2xl:h-[4.5rem] w-screen z-[-2] border-0 
          fixed top-0
          z-[1100]
          transition-all ease-in"
          :class="{
            'shadow-[inset_0_0_0_1000px_rgba(28,42,63,.9)] bg-white': scrolled === true,
            'bg-gpl-blue' : scrolled === true && showMenu === true,
            'bg-none bg-gpl-blue/90' : showMenu === true,
          }">
        <!-- Logo -->
        <div class="w-fit flex  flex-row justify-start xl:block">
            <div class="text-left xl:mr-8">
                <img class="h-8 lg:h-8 align-middle mx-10" src="/imgs/gpl_logo_symbol.png" alt="Green Planet Logistics" />
            </div>
            <div @click="showMenu = !showMenu" class="grow text-right xl:hidden mr-8">
                <button
                type="button"
                class="
                    text-gray-800
                    hover:text-gray-400
                    focus:outline-none focus:text-gray-400
                "
                >
                <svg viewBox="0 0 24 24" class="w-6 h-6 fill-white">
                    <path
                    fill-rule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    ></path>
                </svg>
                </button>
            </div>
        </div>
        <!-- Navlinks Desktop-->
        <div class="hidden xl:block xl:w-full 2xl:w-4/5 text-left h-full my-auto z-[5000] ml-6">
                <router-link 
                  class="uppercase 
                    px-2
                    py-1
                    xl:text-lg 2xl:text-2xl 
                    text-gpl-green px-7 font-semibold 
                    align-middle
                    hover:bg-gpl-green hover:text-white hover:rounded-2xl hover:scale-[115%] hover:shadow-sm hover:shadow-white/10 inline-block
                  " to="/">Home</router-link>
                <router-link 
                  class="
                    uppercase 
                    px-2
                    py-1
                    xl:text-lg 2xl:text-2xl 
                    text-gpl-green px-7 font-semibold 
                    align-middle
                    hover:bg-gpl-green hover:text-white hover:rounded-2xl hover:scale-[115%] hover:shadow-sm hover:shadow-white/10 inline-block
                    " :to="{name: 'home', hash: '#obiettivi'}">Obiettivi</router-link>
                <router-link 
                  class="uppercase
                        px-2
                        py-1 
                        xl:text-lg 2xl:text-2xl 
                        text-gpl-green px-7 font-semibold 
                        align-middle
                        hover:bg-gpl-green hover:text-white hover:rounded-2xl hover:scale-[115%] hover:shadow-sm hover:shadow-white/10 inline-block
                    " :to="{name: 'home', hash: '#la-nostra-rete'}">La rete</router-link>
                <router-link 
                  class="
                    px-2
                    py-1 
                    uppercase 
                    xl:text-lg 2xl:text-2xl 
                    text-gpl-green px-7 font-semibold 
                    align-middle
                    hover:bg-gpl-green hover:text-white hover:rounded-2xl hover:scale-[115%] hover:shadow-sm hover:shadow-white/10 inline-block
                    " :to="{name: 'home', hash: '#news'}">News</router-link>
                <router-link 
                  class="
                    px-2
                    py-1 
                    uppercase 
                    xl:text-lg 2xl:text-2xl 
                    text-gpl-green px-7 font-semibold 
                    align-middle
                    hover:bg-gpl-green hover:text-white hover:rounded-2xl hover:scale-[115%] hover:shadow-sm hover:shadow-white/10 inline-block
                    " :to="{name: 'home', hash: '#contatti' }">Contatti</router-link>
                <!-- <router-link class="uppercase xl:text-lg 2xl:text-2xl text-gpl-green px-7 font-semibold align-middle" to="/">Contatti</router-link> -->
        </div>
        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <div class="h-fit w-full transition-all ease-in mt-3" v-if="showMenu">
            <ul
          :class="showMenu ? 'flex' : 'hidden'"
          class="
            py-8
            flex-col
            space-y-4
            transition-all ease-in
            w-full bg-gpl-blue/90
          "
        >
          <li class="uppercase text-lg font-bold text-gpl-green">
            <router-link class="text-gpl-green" to="/">Home</router-link>
          </li>
          <li class="uppercase text-lg font-bold text-gpl-green">
            <router-link class="text-gpl-green" :to="{name: 'home', hash: '#obiettivi'}">Obiettivi</router-link>
          </li>
          <li class="uppercase text-lg font-bold text-gpl-green">
            <router-link class="text-gpl-green" :to="{name: 'home', hash: '#la-nostra-rete'}">La rete</router-link>
          </li>
          <li class="uppercase text-lg font-bold text-gpl-green">
            <router-link class="text-gpl-green" :to="{name: 'home', hash: '#news'}">News</router-link>
          </li>
          <li class="uppercase text-lg font-bold text-gpl-green">
            <router-link class="text-gpl-green" to="/">Contatti</router-link>
          </li>
        </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Navbar',
    data() {
        return {
            showMenu: false,
            scrolled: !this.$route.name.match('home'),
        };
    },
    beforeMount() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
        if (this.$route.name.match('home')) {
          if (window.pageYOffset > (window.innerHeight * 0.1)) {
            this.scrolled = true;
          } else {
            this.scrolled = false;
          }
        }
      },
    },
};
</script>
