<template>
    <div class="bg-white w-full h-full py-12 px-8">
        <h1 class="font-black text-4xl lg:text-5xl text-gpl-blue  text-center">Obiettivi di Green Planet Logistics</h1>
        <p class="2xl:w-5/6 mx-auto  text-left text-lg lg:text-xl mt-8 w-full px-4">
            Ispiarata dal <b class="text-gpl-blue">“Sustainable and Smart Mobility Strategy”</b> che ha l’obiettivo di rendere gli attuali sistemi di
            mobilità più intelligenti, resilienti, accessibili e sicuri, Green Planet Logistics si pone 9 obiettivi su cui intervenire e da raggiungere.
        </p>
        <div class="w-2xl:w-5/6 mx-auto flex flex-row flex-wrap justify-center mt-4 py-4">
            <MissionCard 
                title="1. Formazione" 
                icon="gpl/graphics/gpl-formazione.png"
                iconAlt="1. Formazione"
            >
                formando il personale addetto alla logistica ad ogni livello, facendo particolare leva sullo studio della Geografia (Fisica, Umana ed Economica) e della Storia Economica con attenzione alla conoscenza delle relazioni politiche e culturali tra i continenti
            </MissionCard>
            <MissionCard 
                title="2. Tecnica di riempimento effettivo dei mezzi" 
                icon="gpl/graphics/gpl-full-truck.png"
                iconAlt="2. Tecnica di riempimento effettivo dei mezzi"
            >
                differenziando il tipo di trasporto (stradale, ferroviario, navale e aereo) e il tipo di mezzo utilizzato
            </MissionCard>
            <MissionCard 
                title="3. Ottimizzazione del carico" 
                icon="gpl/graphics/gpl-optimizing.png"
                iconAlt="3. Ottimizzazione del carico"
            >
                aumentando la capacità dei mezzi sulle lunghe percorrenze, riducendo la mobilità stradale con carico a vuoto e aumentando la portata dei mezzi adibiti all’ultimo miglio
            </MissionCard>
            <MissionCard 
                title="4. Ottimizzazione dei percorsi" 
                icon="gpl/graphics/gpl-path.png"
                iconAlt="4. Ottimizzazione dei percorsi"
            >
                da parte dei pianificatori
            </MissionCard>
            <MissionCard 
                title="5. Utilizzo dei sistemi intermodali" 
                icon="gpl/graphics/gpl-train.png"
                iconAlt="5. Utilizzo dei sistemi intermodali"
            >
                Con l’individuazione della localizzazione strategica degli Interporti
            </MissionCard>
            <MissionCard 
                title="6. Digitalizzazione" 
                icon="gpl/graphics/gpl-document.png"
                iconAlt="6. Digitalizzazione"
            >
                e smaterializzazione dei documenti
            </MissionCard>
            <MissionCard 
                title="7. Interazione dei sistemi informatici" 
                icon="gpl/graphics/gpl-interconnections.png"
                iconAlt="7. Interazione dei sistemi informatici"
            >
                fra committenti e fornitori dei servizi di logistica
            </MissionCard>
            <MissionCard 
                title="8. Diffusione di tecniche di sicurezza integrate" 
                icon="gpl/graphics/gpl-lock.png"
                iconAlt="8. Diffusione di tecniche di sicurezza integrate"
            >
                sia in materia di Safety che di Security
            </MissionCard>
            <MissionCard 
                title="9. Utilizzo dei sistemi di aggregazione moderni" 
                icon="gpl/graphics/gpl-aggregation.png"
                iconAlt="9. Utilizzo dei sistemi di aggregazione moderni"
            >
                sia in materia di Safety che di Security
            </MissionCard>
        </div>
    </div>
</template>

<script>
import MissionCard from './MissionCard.vue';

    export default {
        name: 'Mission',
        components: {
            MissionCard,
        },
        data() {
            return {
                missionPoints: [
                    "Formazione : è necessario formare il personale addetto alla logistica ad ogni livello, facendo particolare leva sullo studio della Geografia (Fisica, Umana ed Economica) e della Storia Economica con attenzione alla conoscenza delle relazioni politiche e culturali tra i continenti;",
                    "Tecnica di riempimento effettivo dei mezzi : differenziando il tipo di trasporto (stradale, ferroviario, navale e aereo) e il tipo di mezzo utilizzato;",
                    "Ottimizzazione del carico: aumentando la capacità dei mezzi sulle lunghe percorrenze, riducendo la mobilità stradale con carico a vuoto e aumentando la portata dei mezzi adibiti all’ultimo miglio; ",
                    "L’aumento di capacità dei mezzi, in particolare sulle lunghe percorrenze",
                    "Aumento della portata dei mezzi adibiti all’ultimo miglio",
                    "L’ottimizzazione dei percorsi da parte dei pianificatori",
                    "L’utilizzo dei sistemi intermodali con individuazione della localizzazione strategica degli Interporti",
                    "La digitalizzazione di alcuni processi strategici della logistica",
                    "La smaterializzazione dei documenti",
                    "Massima Interazione dei sistemi informatici fra committenti a fornitori dei servizi di logistica",
                    "Diffusione delle tecniche di sicurezza integrate, sia in materia di Safety che di Security",
                    "La crescita dimensionale delle aziende anche con l’utilizzo di moderni sistemi di aggregazione",
                ],
            };
        },
    };
</script>

<style>
.manifesto-text {
    column-count: 1;
}

@media only screen and (min-width: 768px) {
    .manifesto-text {
        column-count: 2;
    }
}
</style>
