<template>
    <div class="w-full h-screen bg-gpl-blue/80 pt-8">
        <div class="grid grid-cols-2 h-full">
            <div class="col-span-full xl:col-span-1 h-full align-middle">
                <div class="my-auto px-10 grid grid-rows-8 h-full">
                    <div class="my-auto row-span-1 lg:row-span-4 text-center w-full fading-in-animate">
                        <!-- <img class=" mx-auto xl:mx-0 my-auto h-48 lg:h-64 xl:h-48 2xl:h-64" 
                        src="imgs/gpl_logo_text-min.png" alt="Green Planet Logistics" /> -->
                        <IKImage
                            class="mx-auto xl:mx-0 my-auto h-48 lg:h-64 xl:h-48 2xl:h-64"
                            path="gpl/graphics/gpl_logo_text.png"
                            :lqip="{active:true}"
                            width="420"
                            height="256"
                            alt="Green Planet Logistics"
                        />
                    </div>
                </div>
            </div>
            <div class="col-span-full xl:col-span-1 h-full fading-in-animate z-[101]">
                <div class="grid grid-rows-12 h-full ">
                    <div class="row-span-2 row-start-3 align-bottom">
                        <h1 class="text-white text-6xl lg:text-7xl 2xl:text-8xl font-black xl:text-left">INSIEME</h1>
                        <h2 class="text-white xl:text-gpl-blue text-2xl lg:text-4xl 2l:text-6xl font-black xl:text-left">verso un futuro migliore del trasporto merci</h2>
                        <div class="flex flex-col lg:block py-10 text-center xl:text-left hover:scale-105 transition-all ease-in ">
                            <!-- <a href="" class="rounded-full font-semibold text-gpl-green bg-white text-lg lg:text-xl 2xl:text-3xl px-4 py-3 mx-3">
                                <font-awesome-icon icon="fa-solid fa-paper-plane"></font-awesome-icon> &nbsp;
                                CONTATTACI 
                            </a> &nbsp; -->
                            <router-link :to="{name: 'home', hash: '#la-nostra-rete'}"
                                class="
                                    rounded-full font-semibold text-white 
                                    bg-gpl-green xl:bg-gpl-blue  
                                    text-lg lg:text-xl 2xl:text-3xl 
                                    px-6 py-3 mx-3
                                    hover:shadow-lg hover:shadow-white/20
                                ">LA NOSTRA RETE &nbsp;
                                <font-awesome-icon icon="fa-solid fa-arrow-right"></font-awesome-icon>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* Ellipse 2 */
.overlay {
    position: absolute;
    width: 1544.45px;
    height: 1075.27px;
    left: 318px;
    top: 780.69px;

    background: #61B985;
    transform: rotate(-45.35deg);
}

.fading-in-animate {
    animation: fading-in;
    /* animation-delay: 0.5s; */
    animation-duration: 1.2s;
}

@keyframes fading-in {
    0% {
        z-index: 0;
        opacity: 0;
    }
    50% {
        z-index: 101;
        opacity: 0.3;
    }
    100% {
        z-index: 110;
        opacity: 1;
    }
}
</style>
